import React, { useState, useEffect } from "react";
import JsonData from "../data/data.json";
import { Header } from "./header";
// import { Features } from "./components/features";
import { Companey } from "./Companey";
import { Services } from "./services";
import { Languages } from "./Languages";
// import { Testimonials } from "./components/testimonials";
import { Contact } from "./contact";

const Home = () => {
  const [AmbitmindsData, setAmbitmindsData] = useState({});
  useEffect(() => {
    setAmbitmindsData(JsonData);
  }, []);
  return (
    <>
      <Header data={AmbitmindsData.Header} />
      {/* <Features data={AmbitmindsData.Features} /> */}
      <Companey data={AmbitmindsData.Companey} />
      <Services data={AmbitmindsData.Services} />
      <Languages data={AmbitmindsData.Languages} />
      <Contact data={AmbitmindsData.Contact} />
    </>

  )
}

export default Home
