import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Appser1 from '../Assets/Appser1.svg'
import Appser2 from '../Assets/Appser2.svg'
import Appser3 from '../Assets/Appser3.svg'
import Appser4 from '../Assets/Appser1.svg'


export const Appser = () => {
    return (
        <div id='Desser'>
            <section className='web_Ser'>
                <Row>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Application Devlopment</h1>
                                <p> Application development is the process of creating a computer program or a set of programs to perform the different tasks that a business requires. From calculating monthly expenses to scheduling sales reports, applications help businesses automate processes and increase efficiency.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Appser2} />
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Appser1} />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Waterfall</h1>
                                <p>
                                    The key words for the waterfall method of application development are planning and sequence. The entire project is mapped out in the planning and analysis stages. The customer comes with a very explicit list of features and functionalities for the application. Then, a project manager takes the whole process and maps it out amongst the team.

                                </p>
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >(RAD) Methodology</h1>
                                <p>As you might imagine, the waterfall method of application development presented some big problems. The development process often took a long time to see a working product, teams had to be large to accommodate all the requirements, and tensions ran high when a customer is unhappy with the end product and the whole project has to start over from the beginning.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Appser3} />
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Appser4} />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Agile Methodology</h1>
                                <p> Agile application development is very similar to RAD, but also includes some changes to make it more suitable to larger projects. Agile is iterative, like RAD, but focuses on building features one at a time. Each feature is built in a methodical way in the team, but the customer is involved to see the features and sign off on them before the next feature is developed.</p>
                            </div>
                        </div>
                    </Container>
                </Row>
            </section>

        </div>
    )
}


