import React from 'react'
import {FaReact,FaAngular,FaApple,FaPhp,FaWordpress,FaShopify,FaSwift,FaBootstrap} from 'react-icons/fa'
import { SiAdobexd,SiCodeigniter,SiCss3 ,SiFlutter,SiAdobephotoshop,SiPython,SiWoo} from 'react-icons/si'
import {AiFillAndroid,AiFillHtml5}from 'react-icons/ai'
import{IoLogoLaravel,}from 'react-icons/io5'
const  Ldata =[
    {
        id: 1,
        Use:"web",
        L_title:'React JS',
        icon:<FaReact/>
    },
    { 
        id: 2,
        Use:"designing",
        L_title: 'Adobe XD',
        icon:<SiAdobexd/>
    },
    {
        id: 3,
        Use:"web",
        L_title: 'Angular',
        icon:<FaAngular/>
    },
    {
        id: 4,
        Use:"opensource",
        L_title: 'CodeIgniter',
        icon: <SiCodeigniter/>
    },
    {
        id: 5,
        Use:"web",
        L_title: 'CSS3',
        icon:  <SiCss3/>
    },
    {
        id: 6,
        Use:"mobile",
        L_title: 'Flutter',
        icon: <SiFlutter/>
    },
    {
        id: 7,
        Use:"mobile",
        L_title: 'Android',
        icon: <AiFillAndroid/>
    },
    {
        id: 8,
        Use:"mobile",
        L_title: 'iOS',
        icon: <FaApple/>
    },
    {
        id: 9,
        Use:"web",
        L_title: 'HTML5',
        icon:  <AiFillHtml5/>
    },
    {
        id: 10,
        Use:"web",
        L_title: 'PHP',
        icon:  <FaPhp/>
    },
    {
        id: 11,
        Use:"web",
        L_title: 'WordPress',
        icon:  <FaWordpress/>
    },
    {
        id: 12,
        Use:"web",
        L_title: 'Laravel',
        icon:  <IoLogoLaravel/>
    },
    {
        id: 13,
        Use:"designing",
        L_title: 'Bootstrap',
        icon: <FaBootstrap/>
    },
    {
        id: 14,
        Use:"designing",
        L_title: 'Photoshop',
        icon:  <SiAdobephotoshop/>
    },
    {
        id: 15,
        Use:"web",
        L_title: 'Python',
        icon:  <SiPython/>
    },
    {
        id: 17,
        Use:"ecom",
        L_title: 'Shopify',
        icon:  <FaShopify/>
    },
    {
        id: 18,
        Use:"mobile",
        L_title: 'Swift',
        icon:  <FaSwift/>
    },
    {
        id: 19,
        Use:"ecom",
        L_title: 'Woo.',
        icon:  <SiWoo/>
    },
    
    

]

export default Ldata