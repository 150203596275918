import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { About } from './components/About';
///pages
import Home from './components/Home';
import SmoothScroll from "smooth-scroll";
import JsonData from "./data/data.json";

import Navbarmenu from './components/menu/Navbarmenu';
import { Contact } from './components/contact';
import { Services } from './components/services';
import { Companey } from './components/Companey';

import Engagement from './components/Engagement';
import ScrollToTop from './components/ScrollToTop';
import { Team } from './components/Team';
import { Webser } from './components/Webser';
import { Appser } from './components/Appser';
import { Desser } from './components/Desser';
import { Ecomser } from './components/Ecomser';
import { Proser } from './components/Proser';
import { Testimonials } from './components/testimonials';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


function App() {

  const [AmbitmindsData, setAmbitmindsData] = useState({});
  useEffect(() => {
    setAmbitmindsData(JsonData);
  }, []);


  return (
    <div>
      <Router>
        {/* Add Menu Component */}
        <ScrollToTop />
        <Navbarmenu />
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path="/Company" element={<Companey data={AmbitmindsData.Companey} />} />
          <Route path="/About" element={<About data={AmbitmindsData.About} />} />
          <Route path="/Engagement" element={<Engagement />} />
          <Route path="/contact" element={<Contact data={AmbitmindsData.Contact} />} />
          <Route path="/Team" element={<Team data={AmbitmindsData.Team} />} />
          <Route path="/Webser" element={<Webser />} />
          <Route path="/Appser" element={<Appser />} />
          <Route path="/Desser" element={<Desser />} />
          <Route path="/Ecomser" element={<Ecomser />} />
          <Route path="/Proser" element={<Proser />} />
          <Route path="/testimonials" element={<Testimonials data={AmbitmindsData.Testimonials} />} />
          <Route path="/Services" element={<Services />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
