import React , {useEffect,useState} from 'react';
import  Ldata  from './Ldata';
import {  Col, Row } from 'react-bootstrap';

export const Languages = () => {

  
  const [ldata, setlData] = useState(Ldata);

  useEffect(() => {
    const allli = document.querySelector('.Languages__list ul').querySelectorAll('li');
    function clickHndler(e){
      
     let els = document.querySelectorAll("."+e.target.id);
      els.forEach(function(el){  
        if(el.classList.contains('card-active')){
          setlData([...Ldata]);
            el.classList.remove('card-active');
               }else{
               el.classList.add('card-active');
               
             }
       })
    }
    allli.forEach(n => n.addEventListener('click',clickHndler));
   }, [])
  

  
    
  return (
    <div  id='Technology' className='text-center'>
      <div className='container-fluid L__BG'>
        <div className='section-title_L'>
          <h2>We Work On</h2>
          <Row>
        <Col  md={12} lg={6} className='L_list'>
          <div className='Languages__list'>
            <ul>
              <li id="mobile">Mobile Technologies</li>
              <li id="web">Web Technologies</li>
              <li id="opensource">Open Source</li>
              <li id="ecom">E-Commerces Platform</li>
              <li id="designing">Designing</li>
            </ul>
          </div>
          </Col>
          <Col  md={12} lg={6}> 
            <div className='cards'>
              {
                ldata && ldata.map((VAL) => {
                  return <div className={`card ${VAL.Use}`} key={VAL.id}>
                    <div className="lanuages__icons" ><i>{VAL.icon}</i></div>
                    <div className='discription' >{VAL.L_title}</div>
                  </div>
                })
              }
            </div>
          </Col>
        </Row>
        </div>
      </div>
    </div>
  )
}

